<template>
  <div class="page">
    <div class="wave">
      <svg viewBox="0 0 500 200" height="800" width="100%" preserveAspectRatio="none">
        <path d="M0,200 C300,200 400,0 500,150 L500,00 L0,0 Z" style="fill: var(--hero-color);"></path>
      </svg>
    </div>
    <div class="page-container">
      <div class="hero">
        <div class="heading enter-anim">Screen share without the hassle.</div>
        <div class="big button enter-anim" style="animation-delay: 0.2s;">Coming soon</div>
      </div>
      <div class="feature-container">
        <div class="feature enter-anim" style="animation-delay: 0.4s;">
          <div>
            <div class="heading">
              No install required.
            </div>
            <div class="description">
              Share right from your browser. No downloading or installing any extra apps.
            </div>
          </div>
        </div>
        <div class="feature enter-anim" style="animation-delay: 0.6s;">
          <div>
            <div class="heading">
              No sign-ups.
            </div>
            <div class="description">
              No one needs an account to share or watch. Just share the link.
            </div>
          </div>
        </div>
        <div class="feature enter-anim" style="animation-delay: 0.8s;">
          <div>
            <div class="heading">
              Free to use.
            </div>
            <div class="description">
              It doesn't cost a penny to share your screen with friends or colleagues on Omega.
            </div>
          </div>
        </div>
        <div class="feature enter-anim" style="animation-delay: 1s;">
          <div>
            <div class="heading">
              Video chat included.
            </div>
            <div class="description">
              Hop on video while you're sharing your screen. Voice and text works too, of course.
              <span class="badge">Coming later</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingView"
}
</script>

<style scoped>
.hero {
  position: relative;
  width: auto;
  margin: 8rem 0;
  height: 500px;
  z-index: 10;
}
.hero > .heading {
  display: block;
  max-width: 800px;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 4rem;
}
@keyframes enter-anim {
  0% {
    transform: scale(0.5) translateY(300px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
.enter-anim {
  opacity: 0;
  animation: var(--animation-time) enter-anim forwards;
}
@keyframes wave-anim {
  0% {
    transform: scaleX(1.4);
  }
  100% {
    transform: scaleX(1);
  }
}
.wave {
  margin-top: calc(0px - var(--top-nav-height));
  height: auto;
  position: absolute;
  top: 0;
  width: 100%;
  transform-origin: right;
  animation: var(--animation-time) wave-anim forwards;
}
.page-container {
}
.feature-container {
  position: relative;
  margin-top: -200px;
  z-index: 5;
}
.feature {
  display: flex;
  justify-content: space-between;
  margin: 8rem 0;
  font-size: 1.1rem;
}
.feature > div {
  width: 40%;
}
.feature > div > .heading {
  font-size: 3rem;
  font-weight: bold;
}
.feature:nth-child(odd) {
  flex-direction: row-reverse;
  margin-top: -8rem;
}
.badge {
  font-size: 0.9rem;
  white-space: nowrap;
  background-color: var(--muted-color);
  padding: 0 0.4rem;
  border-radius: 0.2rem;
}
@media screen and (max-width: 768px) {
  .hero > .heading {
    font-size: 3.5rem;
    width: 100%;
  }
  .feature-container {
    margin-top: 300px;
  }
  .feature > div {
    width: 100%;
  }
  .feature:nth-child(odd) {
    margin-top: 0;
  }
  .hero {
    margin: 4rem 0;
  }
  .wave {
    overflow-x: hidden;
    overflow-y: visible;
  }
  .wave > svg {
    width: 150%;
  }
}
</style>
