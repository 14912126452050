<template>
  <div class="top-nav">
    <div class="page-container">
      <span class="brand link">Omega</span>
      <span class="button small">Soon!</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNav"
}
</script>

<style scoped>
.top-nav {
  position: sticky;
  top: 0;
  height: calc(var(--top-nav-height) - 3rem);
  z-index: 1000;
  padding: 1.5rem 0;
  backdrop-filter: blur(18px);
}
.link {
  cursor: pointer;
}
.page-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
