<template>
  <div id="app">
    <top-nav></top-nav>
    <landing-view></landing-view>
    <Footer></Footer>
  </div>
</template>

<script>

import LandingView from "@/views/LandingView";
import TopNav from "@/components/TopNav";
import Footer from "@/components/Footer";
export default {
  name: 'App',
  components: {Footer, TopNav, LandingView},
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
:root {
  --background-color: #03011c;
  --hero-color: #34066B;
  --muted-color: rgba(255, 255, 255, 0.25);
  --muted-color-active: rgba(255, 255, 255, 0.3);
  --text-color: #fff;
  --top-nav-height: 5rem;
  --transition-time: 0.1s;
  --animation-time: 1s;
}
body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  user-select: none;
}
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.page-container {
  padding: 0 2rem;
  max-width: 1500px;
  margin: auto;
}
.brand {
  color: var(--muted-color);
  transition: color var(--transition-time);
  font-weight: bold;
}
.brand:hover {
  color: var(--muted-color-active);
}
.button {
  display: inline-block;
  background-color: var(--muted-color);
  transition: all var(--transition-time);
  border-radius: 4px;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  box-shadow: 0 10px 2rem 0 rgba(0, 0, 0, 0.1);
}
.button:hover {
  background-color: var(--muted-color-active);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.3);
}
.button:active {
  box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.3);
}
.button.big {
  font-size: 1.5rem;
  padding: 1.2rem 4rem;
}
.button.small {
  font-size: 1rem;
  padding: 0.2rem 1.5rem;
}
</style>
