<template>
  <div>
    <div class="footer">
      <div class="page-container">
        <div>
          <div class="heading">
            Omega
          </div>
          <div>
            &copy; 2021
          </div>
        </div>
        <div></div>
        <div></div>
        <div>
          <div class="heading">
            Product
          </div>
          <div>
            <div class="link">App</div>
          </div></div>
        <div>
          <div class="heading">
            Policies
          </div>
          <div>
            <div class="link">Privacy</div>
            <div class="link">Terms</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  background-color: #000;
  padding: 4rem 0;
}
.heading {
  font-weight: bold;
  color: var(--muted-color);
}
.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
